<template>
  <div>
    <goods-tabbar
      :durations="durations"
      @tabbar-click="onTabbarClick"
      ref="tabbar"
    ></goods-tabbar>
    <goods-tab-content :durations="durations" :activeDuration="activeDuration" :currentDuration="currentDuration" ref="tabContent"></goods-tab-content>
  </div>
</template>
<script>
/**
 * 商品状态
 */
const GOODS_STATUS_PAST = 0; // 已结束
const GOODS_STATUS_NOW = 1; // 正在进行中
const GOODS_STATUS_FUTURE = 2; // 未开始

import { fetchDurations, fetchGoods } from "@/api/modules/miaosha";
import moment from "moment";

export default {
  components: {
    "goods-tabbar": () => import("./GoodsTabbar.vue"),
    "goods-tab-content": () => import("./GoodsTabContent.vue"),
  },

  data() {
    return {
      // 时段数据、商品列表、对应的请求参数、是否处于选中状态
      durations: [],

      // 模板中使用变量
      goodsStatusPast: GOODS_STATUS_PAST,
      goodsStatusNow: GOODS_STATUS_NOW,
      goodsStatusFuture: GOODS_STATUS_FUTURE,

      // 当前正在进行中的时段
      currentDuration: {},

      // 点击导航处于选中状态的时段
      activeDuration: {},

    };
  },
  created() {
    this.queryData();
  },
  computed: {},
  methods: {
    queryData() {
      // query time durations
      fetchDurations().then((res) => {
        // 如未返回时段，则说明没有发布商品，停止后续所有逻辑，此处使用durations或者durations都可以判断
        if (!res) {
          return false;
        }

        // 组织durations数据
        var alteredDurations = res.data.listTime;
        alteredDurations.forEach(function (e) {
          e.sale_time = moment(e.sale_time).valueOf();
        });
        this.durations = alteredDurations.sort(function (a, b) {
          return a.sale_time - b.sale_time;
        });

        // 获取当前时间点
        var now = new Date().getTime();
        var durations = this.durations;

        // 按当前时点从duration组中判断在那个时段中，对于前n-1个需要用其下一个辅助判断，对于第n个则直接判断
        for (var d = 0, dl = durations.length; d < dl; d++) {
          if (d < dl - 1) {
            if (now >= durations[d].sale_time) {
              if (now < durations[d + 1].sale_time) {
                durations[d].status = GOODS_STATUS_NOW;
                durations[d].active = true;
              } else {
                durations[d].status = GOODS_STATUS_PAST;
              }
            } else {
              durations[d].status = GOODS_STATUS_FUTURE;
            }
          } else {
            if (now >= durations[d].sale_time) {
              durations[d].status = GOODS_STATUS_NOW;
              durations[d].active = true;
            } else {
              durations[d].status = GOODS_STATUS_FUTURE;
            }
          }
        }

        // 按当前时间决出对应的时段，生成商品列表并渲染到对应的swiper-slide中
        this.currentDuration = durations.find(function (e) {
          return e.status == GOODS_STATUS_NOW;
        });

        // 如果未找到当前正在开售的时段则认为全部没有开售，以第一个时段作为当前时段
        if (!this.currentDuration) {
          this.currentDuration = durations[0];
        }

        // 在durations数组中生成商品列表数据段和参数段
        for (var k in this.durations) {
          this.durations[k].params = {
            pageNo: 1,
            pageSize: 10,
            isLastPage: 1,
            userId: 5,
            saleTime: moment(this.durations[k].sale_time).format(
              "YYYY-MM-DD HH:00:00"
            ),
          };
          this.durations[k].data = [];
        }

        // 设置当前时段为选中时段，并加载其商品
        this.activeDuration = this.currentDuration;
        this.loadActiveDurationGoods();
      });
    },

    // tabbar点击
    onTabbarClick(data) {
      // 设置选中项
      this.durations.forEach((e, i) => {
        if (i == data) {
          e.active = true;
        } else {
          e.active = false;
        }
      });

      // 刷新子组件durations数据，并引发它重新渲染
      this.$refs.tabbar.refresh();
      this.$refs.tabContent.refresh()

      // 设置选中时段并加载对应商品列表
      this.activeDuration = this.durations.find((e) => e.active == true);
      this.loadActiveDurationGoods();
    },

    // 加载商品列表
    loadActiveDurationGoods() {
      var params = this.activeDuration.params;
      // 如果有数据就不加载了，到触底时再加载下一页
      if (!this.activeDuration.data.length) {
        fetchGoods(params).then((res) => {
          if (res.code == 200 && res.data && res.data.list) {
            this.activeDuration.data = this.activeDuration.data.concat(
              res.data.list
            );
            this.$refs.tabContent.refresh()
          }
        });
      }
    },

  },
  watch: {

  }
};
</script>
<style lang="css" scoped>
</style>
